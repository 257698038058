/* src/components/Footer.css */
.footer {
  background-color: #f7f9fc; /* Light background color */
  color: #2c3e50; /* Dark text color */
  padding: 40px 20px;
  text-align: center;
  border-top: 1px solid #e0e0e0; /* Subtle border at the top */
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-section {
  max-width: 300px;
}

.footer-section h4 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #2c3e50; /* Dark text for headings */
}

.footer-section p,
.footer-section ul {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin: 0;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #2c3e50; /* Dark link color */
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  font-size: 0.9rem;
  color: #555;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    max-width: 100%; /* Make sections take full width on mobile */
    text-align: center; /* Center-align text for smaller screens */
    margin-bottom: 20px; /* Add margin between sections */
  }

  .footer-bottom {
    font-size: 0.8rem; /* Slightly smaller text for mobile */
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px; /* Reduce padding on small screens */
  }

  .footer-section h4 {
    font-size: 1.2rem; /* Reduce heading size on small screens */
  }

  .footer-section ul li a {
    font-size: 0.9rem; /* Smaller link text for small screens */
  }
}
