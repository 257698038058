.review-section {
    padding: 80px 40px;
    background-color: #f7f9fc;
  }
  
  .review-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    gap: 40px;
  }
  
  .review-text {
    flex: 1;
    padding-right: 20px;
  }
  
  .review-text h2 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .review-text p {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.8;
  }
  
  .review-text .highlight {
    color: #14476a;
  }
  
  .swiper-container {
    flex: 1;
    max-width: 600px;
  }
  
  .swiper-slide-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #d1d1d1; /* Add border to create a box effect */
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .review-quote {
    font-size: 1.3rem;
    color: #2c3e50;
    margin-bottom: 20px;
    line-height: 1.8;
  }
  
  .review-author {
    font-size: 1.3rem;
    font-weight: bold;
    color: #14476a;
    margin-bottom: 10px;
  }
  
  .rating {
    color: #ffc107;
    font-size: 1.5rem;
    display: flex;
  }
  
  .rating svg {
    margin-right: 5px;
  }
  
  /* Mobile Specific Styling */
  @media (max-width: 768px) {
    .review-content {
      flex-direction: column;
      text-align: center;
    }
  
    .review-text {
      padding-right: 0;
      margin-bottom: 20px;
      max-width: 100%;
    }
  
    .swiper-container {
      max-width: 100%;
    }
  
    .swiper-slide-content {
      padding: 20px;
    }
  
    .review-quote {
      font-size: 1.2rem;
    }
  
    .review-author {
      font-size: 1.1rem;
    }
  
    .rating {
      font-size: 1.2rem;
      justify-content: center;
    }
  
    .rating svg {
      margin-right: 2px;
    }
  }
  