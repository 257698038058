/* src/components/Contact.css */
.contact {
    padding: 80px 40px;
    background-color: #f7f9fc; /* Light background */
    text-align: center; /* Center align the content */
  }
  
  .cta {
    margin: 40px 0;
  }
  
  .cta h3 {
    font-size: 2.5rem; /* Large font size for the main text */
    color: #2c3e50; /* Dark text */
    margin-bottom: 20px;
  }
  
  .cta-link {
    font-size: 2rem; /* Slightly smaller font size for the link */
    color: #1c87c9; /* Bright blue color for the link */
    text-decoration: none; /* Remove underline */
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .cta-link:hover {
    color: #145a85; /* Darker blue on hover */
  }
  
  @media screen and (max-width: 768px) {
    .cta h3 {
      font-size: 2rem; /* Adjust font size for smaller screens */
    }
  
    .cta-link {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
    }
  }
  