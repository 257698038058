.technologies {
  padding: 60px 20px;
  background-color: #f7f9fc; /* Match the overall site background */
  text-align: center;
}

.technologies h2 {
  font-size: 2rem;
  color: #2c3e50; /* Dark text */
  margin-bottom: 40px;
}

.tech-logos {
  overflow: hidden; /* Hide overflow to create a seamless scroll */
  position: relative;
  width: 100%;
}

.scroll-container {
  display: flex; /* Flexbox for easy alignment */
  justify-content: space-around;
  align-items: center;
  width: max-content; /* Ensure container adjusts to its content size */
  animation: scroll 20s linear infinite; /* Infinite scrolling animation */
}

.scroll-container img {
  max-width: 100px; /* Set a uniform max width for all logos */
  height: 80px; /* Set a uniform height for all logos */
  object-fit: contain; /* Ensure logos maintain aspect ratio */
  margin: 0 50px; /* Adjust margin to add more space between logos */
  transition: transform 0.3s ease;
}

.scroll-container img:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

/* Keyframe animation for smooth infinite scroll */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Scroll halfway, as content is duplicated */
  }
}
