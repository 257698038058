.ai-page {
  background-color: #f7f9fc;
  padding: 80px 20px;
  text-align: center;
  color: #2c3e50;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.ai-coming-soon {
  background-color: #14476a;
  color: white;
  padding: 50px 20px;
  border-radius: 12px;
  margin-bottom: 50px;
  animation: fadeIn 1.2s ease-in-out;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.ai-coming-soon h3 {
  font-size: 2.8rem;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.ai-coming-soon p {
  font-size: 1.3rem;
  color: #fff;
  font-weight: 300;
}

.ai-hero {
  background-color: #ffffff;
  padding: 50px 20px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
  animation: slideUp 1.2s ease-out;
}

.ai-hero h1 {
  font-size: 3rem;
  color: #14476a;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 3px;
  background: linear-gradient(135deg, #14476a, #1c587a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ai-hero p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: #555;
}

.ai-hero h2 {
  font-size: 2.2rem;
  margin: 40px 0 20px;
  color: #1c587a;
  text-transform: uppercase;
  border-bottom: 2px solid #14476a;
  display: inline-block;
}

.ai-benefits {
  list-style: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.ai-benefits li {
  font-size: 1.2rem;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
  color: #555;
  animation: slideIn 1.2s ease-in-out;
}

.ai-benefits li:before {
  content: "✓";
  font-size: 1.5rem;
  color: #2575fc;
  position: absolute;
  left: 0;
  top: 0;
}

/* Keyframe Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .ai-page {
    padding: 50px 15px;
  }

  .ai-coming-soon h3 {
    font-size: 2rem;
  }

  .ai-hero h1 {
    font-size: 2rem;
  }

  .ai-hero p {
    font-size: 1rem;
    max-width: 100%;
  }

  .ai-hero h2 {
    font-size: 1.8rem;
  }

  .ai-benefits li {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .ai-hero h1 {
    font-size: 1.8rem;
  }

  .ai-hero h2 {
    font-size: 1.5rem;
  }

  .ai-hero p {
    font-size: 0.9rem;
    line-height: 1.6;
  }

  .ai-benefits li {
    font-size: 0.9rem;
  }

  .ai-coming-soon {
    padding: 40px 15px;
  }

  .ai-coming-soon h3 {
    font-size: 1.8rem;
  }
}
