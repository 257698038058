/* General maintenance styling */
.portfolio-maintenance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: #f7f9fc;
  padding: 20px;
}

.maintenance-message h1 {
  font-size: 3rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.maintenance-message p {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 30px;
}

.maintenance-message img {
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .portfolio-maintenance {
    padding: 40px 20px; /* Add more padding for small screens */
  }

  .maintenance-message h1 {
    font-size: 2.5rem; /* Slightly smaller text for smaller screens */
  }

  .maintenance-message p {
    font-size: 1.2rem; /* Adjust paragraph size for readability */
    margin-bottom: 20px; /* Reduce spacing on mobile */
  }

  .maintenance-message img {
    max-width: 250px; /* Scale down the image for smaller screens */
  }
}

@media (max-width: 480px) {
  .maintenance-message h1 {
    font-size: 2rem; /* Further reduce the heading size for very small screens */
  }

  .maintenance-message p {
    font-size: 1rem; /* Adjust paragraph font size for very small screens */
  }

  .maintenance-message img {
    max-width: 200px; /* Further scale down the image */
  }
}
