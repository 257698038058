.service-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    background-color: #f7f9fc; /* White background */
}

.intro-text {
    max-width: 800px;
    margin-bottom: 50px;
    text-align: center;
}

.intro-text h2 {
    font-size: 3rem;
    color: #2c3e50;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
}

.intro-text p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
}

.service-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    box-sizing: border-box;
}

.service-card {
    background-color: #ffffff;
    padding: 40px 20px; /* Increase padding to make the card taller */
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.service-icon {
    width: 70px;
    height: 70px;
    object-fit: contain; /* Ensure the image fits within the dimensions */
    margin-bottom: 30px; /* Add more space below the icon */
}

.service-card h3 {
    font-size: 1.6rem;
    color: #14476a;
    margin-bottom: 15px;
}

.service-card p {
    font-size: 1rem;
    color: #333;
    line-height: 1.5;
}

/* Initially hide the extra service cards on mobile */
.service-card.hide {
    display: none;
}

/* Show More Button */
.show-more-btn {
    display: none;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #14476a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Medium Screens (Tablets) */
@media (max-width: 992px) {
    .service-cards {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
    }

    .intro-text h2 {
        font-size: 2.5rem;
    }

    .service-card {
        padding: 25px;
    }

    .service-icon {
        width: 60px;
        height: 60px;
        margin-bottom: 18px;
    }

    .service-card h3 {
        font-size: 1.4rem;
        margin-bottom: 12px;
    }

    .service-card p {
        font-size: 0.95rem;
    }
}

/* Small Screens (Mobile) */
@media (max-width: 576px) {
    .service-cards {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .intro-text {
        padding: 0 15px;
        margin-bottom: 40px;
    }

    .intro-text h2 {
        font-size: 2rem;
    }

    .intro-text p {
        font-size: 1rem;
    }

    .service-card {
        padding: 20px;
    }

    .service-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
    }

    .service-card h3 {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    .service-card p {
        font-size: 0.9rem;
    }

    /* Show More Button visible only on mobile */
    .show-more-btn {
        display: block;
    }
}
