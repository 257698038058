.technology-list {
    padding: 40px 15px; /* Smaller padding for a less prominent section */
    background-color: #f7f9fc;
    text-align: center;
}
  
.technology-list h2 {
    font-size: 1.8rem; /* Smaller font size */
    margin-bottom: 30px;
    color: #2c3e50;
}
  
.technology-tabs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
  
.technology-tabs button {
    background: none;
    border: none;
    color: #2c3e50;
    font-size: 1rem; /* Slightly smaller font */
    padding: 8px 16px; /* Reduced padding */
    cursor: pointer;
    border-bottom: 3px solid transparent;
    margin-bottom: 10px;
}
  
.technology-tabs button.active {
    color: #1c587a;
    border-bottom: 3px solid #1c587a;
}

/* Grid container styling */
.technology-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr)); /* Smaller grid items */
    gap: 20px;
    justify-items: center;
    max-width: 90%;
    margin: 0 auto;
    padding-bottom: 30px;
}

/* Individual grid item styling */
.technology-item {
    background-color: #ffffff;
    border: 2px solid #14476a;
    border-radius: 8px;
    padding: 10px; /* Smaller padding */
    width: 110px; /* Reduced size */
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
}

.technology-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.technology-item img {
    max-width: 45px;
    max-height: 45px; /* Reduced logo size */
    object-fit: contain;
    margin-bottom: 10px;
}

.technology-item span {
    font-size: 0.9rem;
    color: #2c3e50;
    font-weight: 600;
}

/* Responsive Design for Medium Screens */
@media (max-width: 1024px) {
    .technology-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
    .technology-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .technology-item {
        width: 90px;
        height: 90px;
    }

    .technology-item img {
        max-width: 35px;
        max-height: 35px;
    }

    .technology-item span {
        font-size: 0.8rem;
    }
}

/* Responsive Design for Extra Small Screens (Mobile) */
@media (max-width: 480px) {
    .technology-list {
        padding: 40px 10px;
    }

    .technology-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .technology-item {
        width: 80px;
        height: 80px;
    }

    .technology-item img {
        max-width: 30px;
        max-height: 30px;
    }

    .technology-item span {
        font-size: 0.7rem;
    }
}
