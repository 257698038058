.intro-section {
    background-color: #f7f9fc; /* Light grayish-blue background */
    background-image: none; /* Remove any background image */
    text-align: center;
    padding: 50px 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add some shadow for a floating effect */
}

.intro-content {
    max-width: 600px;
    margin: 0 auto;
}

.intro-content h1 {
    font-size: 2.5rem;
    color: #14476a;
    margin-bottom: 20px;
    line-height: 1.2;
}

.intro-content p {
    font-size: 1.25rem;
    color: #2c3e50;
    line-height: 1.6;
}
