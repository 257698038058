.technology-list {
    padding: 60px 20px;
    background-color: #ffffff;
    text-align: center;
}
  
.technology-list h2 {
    font-size: 2rem;
    margin-bottom: 40px;
    color: #2c3e50;
}
  
.technology-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
  
.technology-tabs button {
    background: none;
    border: none;
    color: #2c3e50;
    font-size: 1.1rem;
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
}
  
.technology-tabs button.active {
    color: #1c587a;
    border-bottom: 3px solid #1c587a;
}

/* Grid container styling */
.technology-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Ensures all boxes have the same size */
    gap: 20px;
    justify-items: center;
}

/* Individual grid item styling */
.technology-item {
    background-color: #f7f9fc;
    border: 2px solid #14476a;
    border-radius: 8px;
    padding: 20px;
    width: 150px; /* Fixes the size of the boxes */
    height: 150px; /* Ensures all boxes are the same height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
}

.technology-item:hover {
    transform: translateY(-5px); /* Adds a subtle hover effect */
}

.technology-item img {
    max-width: 50px; /* Ensure the image fits within the box */
    max-height: 50px; /* Constrain the image height */
    object-fit: contain; /* Ensure the image maintains aspect ratio */
    margin-bottom: 10px;
}

.technology-item span {
    font-size: 1rem;
    color: #2c3e50;
    font-weight: 600;
}

/* Responsive Design */
@media (max-width: 768px) {
    .technology-grid {
        grid-template-columns: repeat(3, 1fr); /* Adjust grid for smaller screens */
    }

    .technology-item {
        width: 120px;
        height: 120px;
    }

    .technology-item img {
        max-width: 40px;
        max-height: 40px;
    }
}

@media (max-width: 480px) {
    .technology-grid {
        grid-template-columns: repeat(2, 1fr); /* Further adjust grid for very small screens */
    }

    .technology-item {
        width: 100px;
        height: 100px;
    }

    .technology-item img {
        max-width: 35px;
        max-height: 35px;
    }
}
