.services {
  padding: 80px 20px;
  background-color: #f7f9fc; /* Match the background with the header and other sections */
}

.services-intro {
  max-width: 600px;
  margin: 0 auto 40px; /* Center the text and add margin at the bottom */
  text-align: center; /* Center the text content */
  padding-left: 20px; /* Optional: Remove this if you want perfect centering */
}

.services-button {
  font-size: 0.9rem;
  color: #1c587a;
  background: none;
  border: 2px solid #1c587a;
  border-radius: 20px;
  padding: 8px 16px;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
}

.services h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.services-intro p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
}

.service-cards-container {
  display: flex;
  justify-content: center;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr)); /* 3 cards per row */
  gap: 40px; /* Increase gap between cards */
  padding: 0 40px; /* Increase padding to spread the cards */
}

.service-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  position: relative;
  overflow: hidden;
  width: 100%; /* Ensure full width for consistency */
  max-width: 300px; /* Set a maximum width for the cards */
  margin: 0 auto; /* Center the cards */
}
.service-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  background: #2575fc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
}

.service-card h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #2575fc;
}

.service-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.learn-more {
  font-size: 1rem;
  color: #1c587a;
  text-decoration: none;
  margin-top: auto;
  font-weight: bold;
  transition: color 0.3s ease;
}

.learn-more:hover {
  color: #14476a;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.service-card::before {
  content: '';
  position: absolute;
  top: -50px;
  right: -50px;
  width: 150px;
  height: 150px;
  background: rgba(28, 88, 122, 0.1);
  border-radius: 50%;
  transition: transform 0.3s;
}

.service-card:hover::before {
  transform: scale(1.2);
}

/* Mobile and Tablet Responsiveness */
@media screen and (max-width: 1024px) {
  .service-cards {
    grid-template-columns: 1fr; /* Stack service cards on tablet and mobile */
  }

  .service-card {
    margin-bottom: 20px; /* Add space between stacked cards */
  }
}
