/* Starry Blue Night Background */
.starry-bg {
    background: radial-gradient(circle, rgba(44, 62, 80, 0.9) 0%, rgba(28, 88, 122, 1) 100%);
    color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  
  .not-found-container {
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  
  .not-found-title {
    font-size: 3rem;
    color: #fff; /* Change text color to white for contrast */
    margin-bottom: 20px;
    animation: glow 1.5s ease-in-out infinite alternate;
  }
  
  .not-found-text {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .not-found-emoji {
    font-size: 3rem;
    color: #fff; /* Keep emoji colors consistent with the theme */
  }
  
  .not-found-help {
    margin-top: 20px;
    font-size: 1.3rem;
    color: #fff;
  }
  
  .not-found-adventure {
    margin-top: 30px;
    font-size: 1.3rem;
    font-style: italic;
    animation: pulse 1.5s infinite;
    color: #fff;
  }
  
  .home-link {
    color: #14476a; /* Dark blueish link color */
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .home-link:hover {
    color: #fff;
    text-shadow: 0px 0px 10px #14476a; /* Blueish glow on hover */
  }
  
  /* Keyframe animations for dark bluish glowing and pulse effects */
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #14476a, 0 0 20px #14476a, 0 0 30px #14476a;
    }
    to {
      text-shadow: 0 0 20px #14476a, 0 0 30px #14476a, 0 0 40px #14476a;
    }
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Shooting stars animation */
  .shooting-stars {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.svg');
    background-size: cover;
    z-index: -1;
    animation: shooting-stars 2s linear infinite;
  }
  
  @keyframes shooting-stars {
    0% {
      transform: translateX(0) translateY(0);
    }
    100% {
      transform: translateX(-1000px) translateY(1000px);
    }
  }
  
  /* Responsiveness */
  @media screen and (max-width: 1024px) {
    .not-found-title {
      font-size: 2.5rem;
    }
  
    .not-found-text,
    .not-found-help,
    .not-found-adventure {
      font-size: 1rem;
    }
  
    .not-found-emoji {
      font-size: 2rem;
    }
  }
  