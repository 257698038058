.process {
  padding: 80px 20px;
  background-color: #f7f9fc; /* Light background */
}

.process-header {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items horizontally */
  justify-content: center; /* Center items vertically */
  margin-bottom: 60px;
  text-align: center; /* Center the text */
}

.process-button {
  font-size: 0.9rem;
  color: #1c587a;
  background: none;
  border: 2px solid #1c587a;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 20px; /* Add margin below the button */
}

.process-header h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin: 0;
}

.steps {
  display: flex;
  justify-content: space-evenly; /* Evenly distribute space between boxes */
  flex-wrap: wrap;
  gap: 5px; /* Reduced gap for closer boxes */
  text-align: center;
  padding: 0 10px; /* Padding to prevent boxes from touching edges */
}

.step {
  flex: 1;
  min-width: 250px;
  max-width: 280px; /* Slightly reduced max-width to make boxes closer */
  background-color: #fff;
  padding: 15px; /* Reduced padding to make boxes more compact */
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #1c587a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #1c587a;
  margin-bottom: 15px; /* Reduced margin for closer spacing */
}

.step h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px; /* Reduced margin for closer spacing */
}

.step p {
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;
  margin-bottom: 0;
}

.arrow {
  font-size: 2rem;
  color: #2c3e50;
  margin-top: 20px; /* Space between the step and the arrow */
}

/* Mobile and Tablet Responsiveness */
@media screen and (max-width: 768px) {
  .process-header {
      flex-direction: column;
      align-items: center; /* Center items on smaller screens */
  }

  .process-button {
      margin-left: 0; /* Remove left margin for mobile */
      margin-bottom: 20px; /* Add space below the button */
  }

  .steps {
      flex-direction: column;
      align-items: center;
  }

  .arrow {
      display: none; /* Hide arrows on mobile view */
  }
}
