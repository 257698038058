/* General page styling */
.contact-page-container {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  background-color: #f7f9fc;
  min-height: 100vh;
  gap: 40px;
}

/* Main section for two columns */
.contact-main-section {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  gap: 40px;
  justify-content: space-between;
}

/* Right Column - Contact Form */
.contact-form-section {
  order: 2; /* Move the form to the right */
  flex: 1;
  max-width: 600px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.custom-contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.custom-contact-form label {
  font-size: 1.1rem;
  color: #2c3e50;
}

.custom-contact-form input,
.custom-contact-form select,
.custom-contact-form textarea {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f7f9fc;
}

.custom-contact-form textarea {
  resize: none;
}

.custom-submit-btn {
  padding: 15px;
  font-size: 1.2rem;
  background-color: #1c587a;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-submit-btn:hover {
  background-color: #14476a;
}

.form-status {
  color: #2c3e50;
  margin-top: 10px;
  font-size: 1.1rem;
}

/* Left Column - Contact Information */
.contact-info-section {
  order: 1; /* Move the contact info to the left */
  flex: 1;
  max-width: 600px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px; /* Adds space from the left */
}

.contact-info-section h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 15px;
}

.contact-info-section p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.contact-info-section strong {
  font-size: 1.5rem;
  color: #1c587a;
}

/* Social Links */
.social-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.social-links a {
  font-size: 1.5rem;
  color: #1c587a;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #14476a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-main-section {
    flex-direction: column;
  }

  .contact-page-container {
    padding: 20px 10px;
  }

  .contact-info-section {
    padding-left: 0; /* Remove left padding for mobile */
    text-align: center; /* Center-align text for smaller screens */
  }

  .contact-form-section {
    max-width: 100%; /* Ensure form takes up full width on mobile */
  }

  .contact-hero-section h1 {
    font-size: 2.2rem;
  }

  .contact-info-section h2 {
    font-size: 2rem;
  }

  .contact-info-section p {
    font-size: 1rem;
  }

  .custom-submit-btn {
    padding: 12px;
    font-size: 1rem;
  }

  .custom-contact-form textarea {
    height: auto;
  }

  /* Center social links on mobile */
  .social-links {
    justify-content: center;
  }
}

@media (min-width: 769px) {
  /* Keep social links left-aligned for larger screens */
  .social-links {
    justify-content: flex-start;
  }
}
