/* General Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f7f9fc;
  color: #2c3e50;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid #e0e0e0;
  position: relative; /* Ensures the navbar is in the correct layer */
  z-index: 10; /* Keeps the navbar on top */
}

.logo h2 a {
  color: #2c3e50;
  text-decoration: none; /* Remove underline */
}

.logo h2 a:hover,
.logo h2 a:active,
.logo h2 a:visited {
  color: #2c3e50; /* Keeps the text color consistent on all states */
  text-decoration: none; /* Ensure no underline */
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 0;
}

.nav-links li a {
  color: #2c3e50;
  text-decoration: none;
  font-size: 1rem;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #1c587a;
}

/* Burger Menu */
.burger {
  display: none;
  cursor: pointer;
  z-index: 11; /* Ensure burger menu stays on top */
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #2c3e50;
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #f7f9fc;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 9; /* Keep the nav links behind the burger icon */
  }

  .nav-links.active {
    display: flex;
  }

  .burger {
    display: block;
  }
}

/* Burger menu animation */
.burger.open div:nth-child(1) {
  transform: rotate(45deg) translateY(8px);
}

.burger.open div:nth-child(2) {
  opacity: 0;
}

.burger.open div:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px);
}
