.header {
    display: flex;
    justify-content: center; /* Center the entire header content horizontally */
    align-items: center;
    flex-direction: column; /* Stack content vertically */
    padding: 60px 40px; /* Adjusted padding for alignment */
    background-color: #f7f9fc; /* Light background */
    color: #2c3e50; /* Dark text color */
}

.header-content {
    max-width: 80%; /* Increased max-width for better centering */
    text-align: center; /* Center text on all screen sizes */
}

.header-content h1 {
    font-size: 3rem;
    margin: 0 0 20px;
    color: #2c3e50; /* Dark text color */
    line-height: 1.2;
}

.header-content p {
    margin: 20px 0;
    font-size: 1.2rem;
    color: #6c757d; /* Light grey text */
}

.header-content button {
    background-color: #1c587a; /* Blue button color */
    color: white;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.header-content button:hover {
    background-color: #145374; /* Darker blue on hover */
}

.header-graphic {
    max-width: 60%; /* Adjusted for better centering */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Add margin to separate from text */
}

/* Mobile and Tablet Responsiveness */
@media screen and (max-width: 1024px) {
    .header {
        padding: 40px 20px; /* Adjust padding for smaller screens */
    }

    .header-content {
        max-width: 100%;
        text-align: center; /* Center text on smaller screens */
    }

    .header-graphic {
        max-width: 80%; /* Adjusted for better centering on smaller screens */
        margin-top: 20px;
    }
}
