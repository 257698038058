.about-page {
  font-family: 'Poppins', sans-serif;
  background-color: #f7f9fc;
  padding: 0 10%; /* Ensures the layout doesn’t go too wide */
}

/* Circle Button Styling for Section Headers */
.circle-button {
  display: inline-block;
  padding: 8px 16px; /* Adjusted padding */
  border: 2px solid #1c587a; /* New border color */
  border-radius: 20px; /* Makes it rounded */
  font-size: 0.9rem; /* New font size */
  text-transform: uppercase;
  color: #1c587a; /* New text color */
  font-weight: 600;
  background: none; /* Transparent background */
  letter-spacing: 1.5px;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 10px; /* Reduced margin-bottom */
}

.circle-button:hover {
  background-color: #1c587a; /* Hover background */
  color: #fff; /* Text color on hover */
  border-color: #1c587a; /* Border color on hover */
}

/* Section Styling */
.who-we-are, .our-story, .our-services, .three-perks, .cta-section {
  padding: 60px 0;
  text-align: center;
}

.text-block, .story-block, .services-block, .perks-block {
  max-width: 1200px;
  margin: 0 auto;
}

.text-block, .story-block, .services-block {
  text-align: center;
}

/* Headings Styled Like Image */
.text-block h2, .story-block h2, .services-block h2 {
  font-size: 1.2rem;
  color: #555;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
  border: 2px solid #1c587a; /* Adjusted border color */
  border-radius: 20px; /* Adjusted border-radius */
  padding: 8px 16px; /* Updated padding */
  display: inline-block;
  color: #1c587a; /* Adjusted text color */
}

/* "Have a project in mind?" Section */
.cta-section {
  background-color: #14476a; /* Dark blue background */
  color: #ffffff; /* White text for better visibility */
  text-align: center;
  padding: 60px 20px;
  border-radius: 12px;
}

.cta-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ffffff; /* White text for readability */
}

.cta-link {
  font-size: 1.5rem;
  color: #00c3ff; /* Light blue for contrast */
  text-decoration: none;
  font-weight: 600;
}

.cta-link:hover {
  color: #00c3ff; /* Keep color change but no underline */
  border-bottom: none;
}

/* Main content text */
.main-heading, .service-description {
  font-size: 2.5rem;
  font-weight: 700;
  color: #14476a;
  margin-bottom: 20px;
}

.support-text, .story-text, .service-description {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}

/* Three Perks Section (No Circle Layout for Heading) */
.three-perks {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.perks-block {
  width: 100%;
  max-width: 900px;
  text-align: left;
}

.perks-details h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase; /* Normal text style without circle border */
  color: #14476a;
}

/* Accordion for Perks */
.perk-item {
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: color 0.3s ease;
}

.perk-item h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  color: #14476a;
}

.perk-item h3 span {
  font-size: 2rem;
  color: #14476a;
  transition: color 0.3s ease;
}

.perk-item:hover h3 {
  color: #00c3ff;
}

.perk-item p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-top: 10px;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .about-page {
    padding: 0 5%;
  }

  .main-heading, .service-description, .perks-details h2 {
    font-size: 2rem;
  }

  .support-text, .story-text, .service-description {
    font-size: 1rem;
  }

  .perks-block {
    width: 100%;
  }

  .perk-item h3 {
    font-size: 1.2rem;
  }

  .perk-item h3 span {
    font-size: 1.8rem;
  }

  .cta-link {
    font-size: 1.2rem;
  }

  .who-we-are, .our-story, .our-services, .three-perks, .cta-section {
    padding: 40px 0;
  }

  .circle-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
