/* src/components/Blog.css */
.blog {
    padding: 60px 20px;
    background-color: #f4f4f4; /* Light grey background */
    text-align: center;
  }
  
  .blog h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #1c587a; /* Lighter Blue for Section Header */
  }
  
  .blog-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .blog-post {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .blog-post h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #2575fc; /* Slightly darker blue for blog titles */
  }
  
  .blog-post p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .blog-post a {
    color: #2575fc; /* Slightly darker blue for links */
    text-decoration: none;
  }
  